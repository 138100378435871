import request from '@/utils/request'
import { getExcelExport } from '@/utils/auth'

// 查询导出
export function ExcelTaskPage(params) {
  return request({
    url: `${getExcelExport()}excel/task/page`,
    method: 'get',
    params
  })
}
export function downloadUrl(id) {
  return request({
    url: `${getExcelExport()}excel/task/${id}/download-url`,
    method: 'get'
  })
}

